import { getElements } from 'js-utils/src/selector';
import { addClass, removeClass } from 'js-utils/src/attribute';

import init from '../../common/base/init';

// NEWS
// News bubbling up on iPhone

init(() => {
  if(!document.querySelector('.dynamic-illustration--news')) return;

  const articleContainer = getElements('.dynamic-illustration--news .dynamic-illustration__items');
  const articles = getElements('.dynamic-illustration--news .news-box');
  let currentArticle = 0;

  articles.forEach((article) => {
    const cardWidth = 80 + Math.round(Math.random()*20);
    article.style.width = cardWidth + "%";
    const marginLeft = Math.round(Math.random()*(100 - cardWidth))
    article.style.marginLeft = marginLeft + "%";
    article.style.opacity = 1;
  });

  const cycleArticles = () => {
    if(currentArticle < articles.length - 1) {
      currentArticle++;
    } else {
      setTimeout(function() {
        articles.forEach((article) => {
          article.style.opacity = 1;
          article.style.transform = "scale(1)";
        });
      }, 1000);
      currentArticle = 0;
    }

    const offset = articles[currentArticle].offsetTop - 20;
    articleContainer[0].style.transform = "translateY(-" + offset + "px)";

    if(currentArticle > 0) {
      articles[currentArticle - 1].style.opacity = 0;
      articles[currentArticle - 1].style.transform = "scale(0.8)";
    }
  };

  if(articles.length) {
    setInterval(cycleArticles, 5000);
  }
});

// PLAY
// Play videos moving side-to-side

init(() => {
  if(!document.querySelector('.dynamic-illustration--videos')) return;

  const videoContainer = getElements('.dynamic-illustration--videos .dynamic-illustration__items');
  const videos = getElements('.dynamic-illustration--videos .news-box');
  let currentVideo = 0;
  let offset = 0;

  const resetVideos = () => {
    videos[currentVideo].style.opacity = 0;
    videos[currentVideo].style.transform = "scale(0.8)";

    offset = 0;
    currentVideo = 0;

    setTimeout(function() {
      videoContainer[0].style.transform = "translate(0, -50%)";
    }, 100);

    setTimeout(function() {
      videos.forEach((video) => {
        video.style.opacity = 0.8;
        video.style.transform = "scale(0.9)";
      });

      videos[currentVideo].style.transform = "scale(1)";
      videos[currentVideo].style.opacity = 1;
    }, 1000);
  };

  if(videos.length) {
    resetVideos();
  }

  const cycleVideos = () => {
    if(currentVideo < videos.length - 1) {
      currentVideo++;
      offset += videos[currentVideo].offsetWidth + 20;

      setTimeout(function() {
        videoContainer[0].style.transform = "translate(" + offset + "px, -50%)";
      }, 200);
    }
    else {
      resetVideos();
    }

    if(currentVideo > 0) {
      videos[currentVideo - 1].style.opacity = 0;
      videos[currentVideo - 1].style.transform = "scale(0.8)";
    }

    setTimeout(function() {
      videos[currentVideo].style.transform = "scale(1)";
      videos[currentVideo].style.opacity = 1;
    }, 1200);

  };

  if(videos.length) {
    setInterval(cycleVideos, 7000);
  }
});

// ANALYSES
// Expertkommentarer analyses flowing up

init(() => {
  if(!document.querySelector('.dynamic-illustration--analyses')) return;

  const analyses = getElements('.dynamic-illustration--analyses .news-box');
  let currentAnalysis = 0;

  const cycleAnalyses = () => {
    setTimeout(function() {
      removeClass(analyses[currentAnalysis], '-visible');
    }, 100);
    setTimeout(function() {
      removeClass(analyses[currentAnalysis + 1], '-visible');
    }, 200);

    setTimeout(function() {
      removeClass(analyses[currentAnalysis + 2], '-visible');

      if(currentAnalysis < analyses.length - 4) {
        currentAnalysis += 3;
      } else {
        currentAnalysis = 0;
      }

      setTimeout(function() {
        addClass(analyses[currentAnalysis], '-visible');
      }, 400);
      setTimeout(function() {
        addClass(analyses[currentAnalysis + 1], '-visible');
      }, 500);
      setTimeout(function() {
        addClass(analyses[currentAnalysis + 2], '-visible');
      }, 600);
    }, 300);

  };

  if(analyses.length) {
    cycleAnalyses();
    setInterval(cycleAnalyses, 8000);
  }
});

// PRACTICES
// Praxis flowing up

init(() => {
  if(!document.querySelector('.dynamic-illustration--practices')) return;

  const analyses = getElements('.dynamic-illustration--practices .news-box');
  let currentAnalysis = 0;

  const cycleAnalyses = () => {
    setTimeout(function() {
      removeClass(analyses[currentAnalysis], '-visible');
    }, 100);
    setTimeout(function() {
      removeClass(analyses[currentAnalysis + 1], '-visible');
    }, 200);

    setTimeout(function() {
      removeClass(analyses[currentAnalysis + 2], '-visible');

      if(currentAnalysis < analyses.length - 4) {
        currentAnalysis += 3;
      } else {
        currentAnalysis = 0;
      }

      setTimeout(function() {
        addClass(analyses[currentAnalysis], '-visible');
      }, 400);
      setTimeout(function() {
        addClass(analyses[currentAnalysis + 1], '-visible');
      }, 500);
      setTimeout(function() {
        addClass(analyses[currentAnalysis + 2], '-visible');
      }, 600);
    }, 300);

  };

  if(analyses.length) {
    cycleAnalyses();
    setInterval(cycleAnalyses, 8000);
  }
});

// SEMINARS
// Seminars cycling through a Pixel phone

init(() => {
  if(!document.querySelector('.dynamic-illustration--seminars')) return;

  const seminars = getElements('.dynamic-illustration--seminars .news-box');
  let currentSeminar = seminars.length - 1;
  let seminarInBottom = false;

  const resetSeminars = () => {
    seminarInBottom = getElements('.dynamic-illustration--seminars')[0].classList.contains('--bottom') ? true : false;

    seminars.forEach((seminar, i) => {
      setTimeout(function() {
        const scale = 1 - (seminars.length-1-i)/10;
        seminar.style.opacity = scale;
        seminar.style.transform = "translateX(-50%) translateY(10%) scale(" + scale + ")";

        if(seminarInBottom)
          seminar.style.bottom = scale * 100 + "px";
        else
          seminar.style.top = scale * 100 + "px";
      }, (seminars.length - i) * 10);
    });

    currentSeminar = seminars.length - 1;
  };

  if(seminars.length) {
    resetSeminars();
  }

  const cycleSeminars = () => {
    seminars[currentSeminar].style.opacity = 0;
    seminars[currentSeminar].style.transform = "translateX(-50%) translateY(100%) scale(1.1)";
    seminars[currentSeminar].style.pointerEvents = "none";

    if(currentSeminar > 0) {
      currentSeminar--;
    } else {
      resetSeminars();
    }

    for (let i = currentSeminar; i >= 0; i--) {
      setTimeout(function() {
        const scale = 1 - (currentSeminar-i)/10;
        seminars[i].style.opacity = scale;
        seminars[i].style.transform = "translateX(-50%) translateY(10%) scale(" + scale + ")";
        seminars[i].style.pointerEvents = "all";
      }, (seminars.length - i) * 100);
    }
  };

  if(seminars.length) {
    setInterval(cycleSeminars, 6000);
  }
});
