import init from '../../common/base/init';

// Set animation duration on news ticker depending on width

init(() => {
  const newsTicker = document.querySelector('.news-ticker ul');
  if(!newsTicker) return;

  const tickerWidth = newsTicker.offsetWidth;
  const animationDuration = tickerWidth/100;
  newsTicker.style.animationDuration = animationDuration + 's';
});
