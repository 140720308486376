import initSmartOutline from 'js-utils/src/smart-outline';

import init from './init';

init(() =>
  initSmartOutline([
    'input:focus',
    'button:focus',
    'textarea:focus',
    'select:focus',
    '.choices.is-focused',
    '.choices__item.is-highlighted',
  ])
);
