import { getElements } from 'js-utils/src/selector';
import { removeClass } from 'js-utils/src/attribute';
import { addEventListener } from 'js-utils/src/event';
import init from '../base/init';

// toggle children checkboxes on parental checkbox change

init(() => {
  const triggers = getElements('.categories-search-filter .checkbox.\\--root input[type=checkbox]');
  if(!triggers.length) return;

  triggers.forEach((trigger) => {
    trigger.addEventListener('change', () => {
      getElements('ul .checkbox input[type=checkbox]', trigger.closest('li')).forEach((checkbox) => {
        checkbox.checked = trigger.checked;
      });
    });
  });
});

// expand/collapse children checkbox lists on toggle click

init(() => {
  const togglers = getElements('.categories-search-filter li button');
  if(!togglers.length) return;

  togglers.forEach((toggler) => {
    toggler.addEventListener('click', (e) => {
      e.preventDefault();
      const sub = toggler.closest('li').querySelector('ul');
      sub.classList.toggle('--display');
      sub.classList.add('--display-triggered');
      toggler.classList.toggle('--expanded');
    });
  });
});

// display sublists that have at least one checkbox checked on page load

init(() => {
  const roots = getElements('.categories-search-filter > ul > li');
  if(!roots.length) return;

  roots.forEach((root) => {
    const subTotal = root.querySelectorAll('.checkbox:not(.\\--root) input[type=checkbox]').length;
    if(subTotal) {
      const subChecked = root.querySelectorAll('.checkbox:not(.\\--root) input[type=checkbox]:checked').length;
      if(subChecked && subChecked != subTotal) {
        root.querySelector('button').classList.add('--expanded');
        root.querySelector('ul').classList.add('--display');
      }
    }
  });
});

// toggle all checkboxes

init(() => {
  const togglers = document.querySelectorAll('.categories-search-filter .-toggle-all');
  if(!togglers.length) return;

  togglers.forEach(toggler => {
    const checkboxes = toggler.closest('.categories-search-filter').querySelectorAll('.checkbox input[type=checkbox]');

    toggler.addEventListener('click', (e) => {
      e.preventDefault();
      checkboxes.forEach(i => i.checked = true);
    });
  });
});

// untoggle all checkboxes

init(() => {
  const togglers = document.querySelectorAll('.categories-search-filter .-untoggle-all');
  if(!togglers.length) return;

  togglers.forEach(toggler => {
    const checkboxes = toggler.closest('.categories-search-filter').querySelectorAll('.checkbox input[type=checkbox]');

    toggler.addEventListener('click', (e) => {
      e.preventDefault();
      checkboxes.forEach(i => i.checked = false);
    });
  });
});

// visually preselect parent categories

init(() => {
  const checkboxes = getElements('.categories-search-filter li li input[type=checkbox]');
  if(!checkboxes.length) return;

  checkboxes.forEach(checkbox => {
    const parent = checkbox.closest('ul');
    const parentCheck = parent.closest('li').querySelector('.checkbox.\\--root');
    const parentCheckbox = parentCheck.querySelector('input[type=checkbox]');

    if(checkbox.checked) {
      parentCheck.classList.add('checkbox--preselected');
    }

    parentCheckbox.addEventListener('change', () =>
      parentCheck.classList.remove('checkbox--preselected')
    );

    checkbox.addEventListener('change', () => {
      const childCheckboxes = parent.querySelectorAll('input[type=checkbox]');
      const childCheckboxesChecked = parent.querySelectorAll('input[type=checkbox]:checked');

      parentCheck.classList.toggle('checkbox--preselected', childCheckboxesChecked.length > 0);
      parentCheckbox.checked = childCheckboxes.length === childCheckboxesChecked.length;
    });
  });

  addEventListener(
    document.querySelectorAll('.categories-search-filter .-untoggle-all'),
    'click',
    () => removeClass(
      document.querySelectorAll('.categories-search-filter .checkbox--preselected'),
      'checkbox--preselected'
    ),
  );
});
