import { getElements } from 'js-utils/src/selector';
import { addClass, removeClass } from 'js-utils/src/attribute';
import Fuse from 'fuse.js';
import init from '../../common/base/init';

// expand/collapse children checkbox lists on toggle click

init(() => {
  const containers = getElements('.bundle-list');
  if (!containers.length) return;

  const searchAttrs = ['title', 'excerpt'];

  Array.from(containers).map(container => {
    const input = container.querySelector('.-search input');
    const emptyState = container.querySelector('.-empty-state');
    const fullTree = getElements('li', container);
    const objects = getElements('.\\--searchable-files', container);
    if (!input || !objects.length) return;
    const searchData = [];

    Array.from(objects).map((object, index) => {
      const data = { index };
      Array.from(searchAttrs).map(
        attr => (data[attr] = object.getAttribute('data-' + attr))
      );
      searchData.push(data);
    });

    const fuse = new Fuse(searchData, {
      threshold: 0.2,
      keys: searchAttrs,
      ignoreLocation: true,
    });

    input.addEventListener('input', () => {
      const searchString = input.value.trim();

      if (searchString) {
        container.classList.add('--searching');

        removeClass(fullTree, '--revealed');
        removeClass(fullTree, '--highlighted');
        addClass(fullTree, '--hidden');
        addClass('ul.--root', '--no-search-hits');
        const results = searchString.length
          ? fuse.search(searchString)
          : searchData;

        if (results.length) {
          Array.from(results).map(result => {
            const index = searchData.findIndex(i => i.index === result.refIndex);
            let resultInDom = objects[index];

            addClass(resultInDom, '--highlighted');

            while (resultInDom.nodeName === 'LI') {
              removeClass(resultInDom, '--hidden');
              addClass(resultInDom, '--revealed');

              resultInDom = resultInDom.parentElement.parentElement;

              removeClass(resultInDom.parentElement, '--no-search-hits');
            }
          });

          removeClass(emptyState, '--visible');
        } else {
          addClass(emptyState, '--visible');
        }
      } else {
        container.classList.remove('--searching');
        removeClass(emptyState, '--visible');
      }
    });
  });
});
