const options = {
  removeItems: true,
  removeItemButton: true,
  resetScrollPosition: false,
  loadingText: ``,
  noResultsText: 'Inga resultat',
  noChoicesText: 'Inga alternativ',
  itemSelectText: 'Klicka för att välja',
  shouldSort: false,
  addItemText: (value) => {
    return `Klicka för att välja <b>“${value}”</b>`;
  },
};

export default options;
