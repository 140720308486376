import { getElements } from 'js-utils/src/selector';

import init from '../../common/base/init';

const makeToggable = (toggler) => {
  const list = toggler.closest(`ul`);
  const listItem = toggler.closest(`li`);
  list.classList.add(`--display-triggered`)
  listItem.querySelector(`button`).classList.toggle(`--expanded`)

  // Toggle sub folders
  Array.from(list.querySelectorAll(`:scope > li > ul`)).map(x => x.classList.toggle(`--display`))
}

init(() => {
  const togglers = getElements('.library-folders li label.-folder');
  if(!togglers.length) return;

  togglers.forEach((toggler) => {
    toggler.addEventListener('click', e => {
      e.preventDefault();

      if(window.location.href.includes("ordlista")) {
        const sub = toggler.closest('li').querySelectorAll(':scope > ul');

        sub.forEach(function(ul) {
          ul.classList.toggle('--display');
          ul.classList.add('--display-triggered');
        });

        toggler.closest('li').querySelector('button').classList.toggle('--expanded');
      } else {
      fetchContents(e, toggler)

      makeToggable(toggler)
      }
    });
  });
});

const fetchContents = (e) => {
  const folderList = e.currentTarget.closest(`ul`)
  const folderId = folderList.dataset.folderId
  const folderName = folderList.dataset.folderName
  const parentName = folderList.dataset.parentName

  let url
  if (window.location.href.includes("arkiv")){
    let root_url = `${window.location.href.replace("/arkiv", "")}/api`
    if (folderId === '' || folderId === 'null'){
      url = `${root_url}?archive=${true}&archive_type=court_case&folder_name=${folderName}&parent_name=${parentName}`
    } else {
      url = `${root_url}/${folderId}?archive=${true}`
    }
  }else{
    url = `${window.location.href}/api/${folderId}`
  }

  if(!folderList.dataset.fetched) {
    fetch(`${url}`).then(response => response.json()).then(json => {
      const folders = []
      const files = []
      const fileContainer = document.createElement(`ul`)
      fileContainer.classList.add(`--display`)
      fileContainer.classList.add(`--display-triggered`)
      const dataLastVisitedAtEl = document.querySelector('[data-last-visited-at]')

      Array.from(json.folders).map(folder => {
        const list = document.createElement(`ul`)
        const labelContainer = document.createElement(`li`)
        const folderSvg = document.getElementsByClassName(`pkg-folder-icon`)[0].cloneNode(true)

        folderSvg.classList.remove(`pkg-folder-icon`)

        list.classList.add(`--display`);
        list.classList.add(`--display-triggered`);
        list.dataset.newItems = false;
        list.dataset.folderId = folder.id;
        list.dataset.folderName = folder.name;
        list.dataset.parentName = folder.parent_name;

        const isNew = new Date(dataLastVisitedAtEl.dataset.lastVisitedAt) < new Date(folder.last_published_at);

        labelContainer.classList.add(`--expandable-any`)
        labelContainer.insertAdjacentHTML(`afterbegin`, `
            <label class="-folder">
              <span class="-folder__title">${folder.name}</span>
              ${isNew || folder.is_new ? `<span class="is-new">Nytt</span>` : ``}
            </label>
            <button type="button" title="Toggle list">Toggle list</button>
          `);

        labelContainer.querySelector(`label`) .insertAdjacentElement(`afterbegin`, folderSvg)
        list.insertAdjacentElement(`beforeend`, labelContainer)

        list.addEventListener(`click`, e => fetchContents(e))

        folders.push(list)
      })

      Array.from(json.files).map(file => {
        const labelContainer = document.createElement(`li`)
        labelContainer.className = `--searchable-files`

        const fileSvg = document.getElementsByClassName(`pkg-file-icon`)[0].cloneNode(true)

        fileSvg.classList.remove(`pkg-file-icon`)
        const isNew = new Date(dataLastVisitedAtEl.dataset.lastVisitedAt) < new Date(file.created_at);

        labelContainer.insertAdjacentHTML(`afterbegin`, `
          <a href="${file.url}">
            <label class="-file">
              <span class="-file__title">${file.packageable.title}</span>
              ${isNew || file.is_new ? `<span class="is-new">Nytt</span>` : ``}

              ${file.packageable.introduction ? `<div role="tooltip" class="-file__intro">${file.packageable.introduction}</div>` : ``}
            </label>
          </a>
        `)

        labelContainer.querySelector(`label`).insertAdjacentElement(`afterbegin`, fileSvg)

        files.push(labelContainer)
      });

      if (json.is_demo === true && json.package_count > 2) {
        const notificationContainer = document.createElement(`li`)
        notificationContainer.className = `--additional-resources-banner`

        notificationContainer.insertAdjacentHTML(`afterbegin`, `
        <span>Beställ nu för att se alla nyheter under denna mapp. (${json.package_count})</span>
      `)
        files.push(notificationContainer)
      }

      folders.map(folder => {
        folderList.querySelector(`li`).insertAdjacentElement(`beforeend`, folder)
        const folderItem = folder.querySelector(`.-folder`)
        folderItem.addEventListener(`click`, () => makeToggable(folderItem))
      });
      files.map(file=> {
        fileContainer.insertAdjacentElement(`beforeend`, file)
      });

      folderList.querySelector(`li`).insertAdjacentElement(`beforeend`, fileContainer)
    }).catch(e => console.log(e))
  }

  folderList.dataset.fetched = true
}
