import flatpickr from 'flatpickr';
import { Swedish } from "flatpickr/dist/l10n/sv.js"

import { getElements } from 'js-utils/src/selector';

import init from '../base/init';

init(() => {
  const inputs = getElements('.js--flatpickr');
  if(!inputs.length) return;

  inputs.forEach((input) => {
    const dateOnly = input.hasAttribute('data-date-only');

    const options = {
      dateFormat: dateOnly ? 'Y-m-d' : 'Y-m-d H:i',
      enableTime: !dateOnly,
      time_24hr: true,
    };

    flatpickr.localize(Swedish);
    flatpickr.l10ns.default.firstDayOfWeek = 1;

    flatpickr(input, options);
  });
});
