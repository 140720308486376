import { getElements } from "js-utils/src/selector"
import { addClass, removeClass } from "js-utils/src/attribute"
import { debounce } from "underscore"
import init from "../../common/base/init"

init(() => {
  const containers = getElements(".bundle-list")
  if (!containers.length) return

  Array.from(containers).map((container) => {
    const input = container.querySelector(".-search-api input")
    const emptyState = container.querySelector(".-empty-state")
    const fullTree = getElements("li", container)

    if (!input) return

    input.addEventListener(
      "input",
      debounce(async () => {
        const searchString = input.value.trim()

        if (searchString) {
          container.classList.add("--searching")

          removeClass(fullTree, "--revealed")
          removeClass(fullTree, "--highlighted")
          addClass(fullTree, "--hidden")
          addClass("ul.--root", "--no-search-hits")

          const url = `${window.location.href}/api/search?q=${searchString}`

          let searchResultsContainer =
            document.querySelector(`.search-api-results`)

          if (!searchResultsContainer) {
            searchResultsContainer = document.createElement(`ul`)
            searchResultsContainer.classList.add(`search-api-results`)
            document
              .querySelector(`.bundle-list`)
              .appendChild(searchResultsContainer)
          }

          searchResultsContainer.innerHTML = ``

          let resultsJSON
          const results = await fetch(`${url}`)

          if (results) resultsJSON = await results.json()

          if (resultsJSON && resultsJSON.files && resultsJSON.files.length) {
            resultsJSON.files.forEach((file) => {
              const item = document.createElement(`li`)
              item.innerHTML = `
                <li class="--searchable-files">
                  <a href="${file.url}">
                    <label class="-file">
                      <svg aria-hidden="true" class="">
                        <use xlink:href="${container.dataset.spritePack}"></use>
                      </svg>
                      <span class="-file__title">${file.packageable.title}</span>
                      <div role="tooltip" class="-file__intro">${file.packageable.introduction}</div>
                    </label>
                  </a>
                </li>
              `
              searchResultsContainer.appendChild(item)
            })

            removeClass(emptyState, "--visible")
          } else {
            addClass(emptyState, "--visible")
          }
        } else {
          container.classList.remove("--searching")
          removeClass(emptyState, "--visible")
        }
      }, 500)
    )
  })
})
