const init = (fn) => {
  if(typeof Turbolinks !== 'undefined') {
    document.addEventListener('turbolinks:load', fn);
  }
  else {
    document.readyState === 'loading'
      ? document.addEventListener('DOMContentLoaded', fn)
      : fn();
  }
};

export default init;
