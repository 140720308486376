import init from "../../common/base/init";

// onClick handler

init(() => {
  const newsletterBannerBtn = document.querySelector(
    ".newsletter-banner__text"
  );

  const newsletterModal = document.getElementById("newsletter-modal");

  if (newsletterBannerBtn && newsletterModal) {
    newsletterBannerBtn.onclick = () => {
      newsletterModal.A11yDialogInstance.show();
    };
  }
});
