import { getElements } from 'js-utils/src/selector';
import { addEventListener } from 'js-utils/src/event';
import { removeClass, toggleClass } from 'js-utils/src/attribute';
import accessibleNav from 'accessiblenav.js';

import init from '../base/init';

init(() => {
  // scrolled
  const header = document.querySelector('.header');
  if(header) {
    const setScrolled = () => toggleClass(header, 'header--scrolled', window.pageYOffset > 0);
    window.addEventListener('scroll', setScrolled);
    setScrolled();
  }

  // nav toggling
  const togglers = getElements('.header-tgl');
  if(togglers.length) {
    addEventListener(togglers, 'click', function(e, triggerEl) {
      e.preventDefault();
      const nav = triggerEl.closest('.header-nav');
      if(nav) {
        const isActive = nav.classList.contains('--active');
        removeClass('.header-nav', '--active');
        nav.classList.toggle('--active', !isActive);
      }
    });
  }

  // dropdown for main nav
  const navMain = document.querySelector('.header-nav--main');
  if(navMain) {
    accessibleNav(navMain, {
      selSub: 'ul',
      selItem: 'li',
      selButton: 'a',
      classItemActive: 'do-show-sub', // doesn't work in IE with -- prefix
      classEnabled: false,
      click: true,
      mouseover: false,
      keypress: true,
      outsideClick: true,
      escPress: true,
    });
  }

  // dropdown for hnav
  const navBar = document.querySelector('.nav-bar__container');
  if(navBar) {
    accessibleNav(navBar, {
      selSub: 'ul',
      selItem: 'li',
      selButton: 'a',
      classItemActive: 'do-show-sub', // doesn't work in IE with -- prefix
      classEnabled: false,
      click: true,
      mouseover: false,
      keypress: true,
      outsideClick: true,
      escPress: true,
    });
  }

  // test button
  const testBtn = document.querySelector('.header .-test-btn');
  if(testBtn) {
    window.addEventListener('scroll', () => {
      toggleClass(testBtn, '--visible', window.pageYOffset > 400);
    });
  }
});
