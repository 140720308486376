const step2 = () => {
  /* const addCouponButton = document.getElementById('coupon-code-button');
  const closeCouponButton = document.getElementById('close-coupon-form-button');
  const couponForm = document.getElementById('coupon-code-form');
  const discountInput = document.getElementById('discount-code-input');
  const form = couponForm.firstElementChild;

  addCouponButton.addEventListener('click', function() {
    couponForm.style.display = 'block';
    addCouponButton.style.display = 'none';
  });

  closeCouponButton.addEventListener('click', function() {
    couponForm.style.display = 'none';
    addCouponButton.style.display = 'block';
    discountInput.value = '';
  });

  form.addEventListener('submit', function(e) {
    e.preventDefault();

    let couponDiscount = 5;

    let couponInfoLeft = document.createElement('div');
    couponInfoLeft.classList.add('summary__item__left');
    couponInfoLeft.innerText = couponDiscount+'% coupon discount';

    let couponInfoRight = document.createElement('div');
    couponInfoRight.classList.add('summary__item__right');
    couponInfoRight.classList.add('highlight-red');
    couponInfoRight.innerText = '-703 kr';

    addCouponButton.remove();
    couponForm.remove();

    let container = document.createElement('div');
    container.classList.add('-coupon-discount-final');

    container.append(couponInfoLeft);
    container.append(couponInfoRight);

    document.getElementById('coupon-code-section').append(container);

  }); */

  const autoSubmit = document.querySelector('input[name="purchase-auto-submit"]');
  if (autoSubmit) {
    const purchaseForm = document.getElementById('new_order');
    if(!purchaseForm) return;
    purchaseForm.submit();
  }

  // Payment owner inputs group
  // Iterate through all payment method inputs first
  document.querySelectorAll('.-owner input').forEach( (el) => {
    const validateOrderType = () => {
      const formContainer = document.querySelector('.details');
      const orderedByType = document.querySelector('input[name="order[ordered_by_type]"]:checked');
      const orderedById = document.querySelector('input[name="order[ordered_by_id]"]');

      if(orderedByType.value === "Company") {
        const companyId = document.querySelector('input[name="company_id"]');
        formContainer.classList.add('--company');
        formContainer.classList.remove('--user');

        if(companyId)
          orderedById.value = companyId.value;
      } else {
        const userId = document.querySelector('input[name="user_id"]');
        formContainer.classList.add('--user');
        formContainer.classList.remove('--company');

        if(userId)
          orderedById.value = userId.value;
      }
    }

    el.addEventListener('click', validateOrderType);

    validateOrderType();
  });


  // Payment method inputs group
  // Iterate through all payment method inputs first
  document.querySelectorAll('.-method input').forEach( (el) => {
    el.addEventListener('click', function() {
      // Get the monthly billing period elements
      const monthlyPeriodContainer = document.querySelector('.-period--monthly');
      if (!monthlyPeriodContainer) return;
      const monthlyPeriodInput = monthlyPeriodContainer.querySelector('input');

      // 0 = Invoice
      // 1 = Credit Card
      if(el.value === "0" && el.checked === true) {
        monthlyPeriodInput.disabled = true;
        monthlyPeriodContainer.classList.add('--disabled');
      } else {
        monthlyPeriodInput.disabled = false;
        monthlyPeriodContainer.classList.remove('--disabled');
      }
    });
  });

  // Payment billing period inputs group
  // Iterate through all payment billing periods first
  document.querySelectorAll('.-period input').forEach( (el) => {
    el.addEventListener('click', function() {
      // Get the monthly billing period elements
      const invoiceMethodContainer = document.querySelector('.-method--invoice');
      if (!invoiceMethodContainer) return;
      const invoiceMethodInput = invoiceMethodContainer.querySelector('input');

      // 0 = Yearly
      // 1 = Monthly
      if(el.value === "1" && el.checked === true) {
        invoiceMethodInput.disabled = true;
        invoiceMethodContainer.classList.add('--disabled');
      } else {
        invoiceMethodInput.disabled = false;
        invoiceMethodContainer.classList.remove('--disabled');
      }
    });
  });

  //increase / decrease the amount of users
  let usersAmount = 1;
  let pricePerUser = 1690;
  let totalPrice = 0;
  let discount = 0;
  let discountedPrice = 0;


  let updateAmount = () => {

    if( usersAmount > 9 ){
      discount = 10;
      document.querySelector('.-discount-section').classList.remove('hidden');
    }else if ( usersAmount > 4 ){
      discount = 5;
      document.querySelector('.-discount-section').classList.remove('hidden');
    }else {
      discount = 0;
      document.querySelector('.-discount-section').classList.add('hidden');
    }

    totalPrice = pricePerUser*usersAmount;
    discountedPrice = totalPrice * (1-(discount/100));

    document.querySelector('.-discount-section .discount-percentage').innerHTML = discount;
    document.querySelector('.-discount-section .user-amount').innerHTML = usersAmount;
    document.querySelector('.-discount-section .discount-amount').innerHTML = totalPrice-discountedPrice;

    document.querySelector('.total-price').innerHTML = discountedPrice;

    updateUserInformation();
  }

  let updateUserInformation = () => {
    document.querySelector('.user-amount .user-number').innerHTML = usersAmount;
    document.querySelector('.subtotal-price').innerHTML = totalPrice;
  }

  let adjustUserAmount = (inc) => {
    if( inc == 'true' && usersAmount < 15 ){
      usersAmount += 1;
    }else if ( inc == 'false' && usersAmount > 1) {
      usersAmount -= 1;
    }
    updateAmount();
  }

  document.querySelectorAll('.user-amount__control').forEach( (el) => {
    el.addEventListener('click', function(){
      let increase = this.getAttribute('data-increase');
      adjustUserAmount(increase);
    })
  });
}

export default step2;
