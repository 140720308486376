import init from '../../common/base/init';

import './purchase-flow/company-creation-modal';
import step1 from './purchase-flow/step-1';
import step2 from './purchase-flow/step-2';

init(() => {

  // step 1
  if( document.getElementById('range-slider') ) {
    step1();
  }

  // step 2
  if( document.getElementById('purchase-step-2') ) {
    step2();
  }

});
