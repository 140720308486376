// TODO: a11y

import { getElements } from 'js-utils/src/selector';

import init from './init';

init(() => {
  const toggleTrigger = getElements('.toggle');
  if(!toggleTrigger.length) return;

  toggleTrigger.forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      const targets = getElements('.' + trigger.getAttribute('data-target'));

      targets.forEach((target) => {
        const parent = target.closest('.togglable');
        const classOpen = 'open';
        const classClose = 'closed';

        if(parent.classList.contains(classOpen)) {
          parent.classList.remove(classOpen);
          parent.classList.add(classClose);
        }
        else {
          parent.classList.remove(classClose);
          parent.classList.add(classOpen);
          parent.classList.add('open-triggered');
        }
      });
    });
  });
});
