import {
  addEventListener,
  delegateEventListener,
} from "js-utils/src/event"
import initFocusTrap from 'focus-trap'
import Cookies from "js-cookie"

import init from "../../common/base/init"

init(() => {
  const container = document.querySelector(`.lxn-cookie-consent-overlay`)
  if (!container) return

  const focusTrap = initFocusTrap(container)
  const buttonAll = document.querySelector(`.lxn-cookie-consent__cta__all`);
  const buttonNecessary = document.querySelector(`.lxn-cookie-consent__cta__necessary`);
  focusTrap.activate()
  buttonAll.focus()
  document.documentElement.classList.add(`--disable-scroll`)

  buttonAll.addEventListener(`click`, (e) => {
    const { value } = e.target.dataset
    Cookies.set(container.dataset.cookieName, value, { expires: 365 })
    focusTrap.deactivate()
    document.documentElement.classList.remove(`--disable-scroll`)
    container.remove()

    if (value == `all`) {
      const script = document.createElement(`script`)
      script.type = `text/javascript`
      script.text = document.querySelector(
        `.js--cookie-consent-script`
      ).innerHTML
      document.body.appendChild(script)
    }
  })

  buttonNecessary.addEventListener(`click`, (e) => {
    const { value } = e.target.dataset
    Cookies.set(container.dataset.cookieName, value, { expires: 365 })
    focusTrap.deactivate()
    document.documentElement.classList.remove(`--disable-scroll`)
    container.remove()
  })
})

// resetter

delegateEventListener(`.js--cookie-consent-reset`, `click`, (e, triggerEl) => {
  Cookies.remove(triggerEl.dataset.cookieName)
  window.scrollTo(0, 0)
  window.location.reload()
})
