// filter's panel toggler for mobile

import init from '../base/init';

init(() => {
  const toggler = document.querySelector('.search-aside__toggler button');
  if(!toggler) return;

  const container = toggler.closest('.search-aside');
  const closeBtn = container.querySelector('.search-aside__form__close');

  toggler.addEventListener('click', (e) => {
    e.preventDefault();
    container.classList.toggle('--toggled-on');
    document.documentElement.classList.toggle('--disable-scroll');
    if(container.classList.contains('--toggled-on')) {
      closeBtn.focus();
    }
  });

  closeBtn.addEventListener('click', (e) => {
    e.preventDefault();
    toggler.click();
  });
});
