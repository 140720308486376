import ahoy from "ahoy.js";

// Rails example
// <%= link_to image_tag("logos/#{bg_color_type == 'dark' ? 'white' : 'purple'}.svg", alt: 'Bilda logo'), root_path, data: { 'track-event': 'click', 'ga-category': 'ga cat', 'ga-action': 'ga action', 'ga-label': 'ga label', 'ga-value': '1' } %>

// HTML Example
// <a href="http://google.com" target="_blank" data-track-event="click" data-ga-category="google cat" data-ga-label="google label" data-ga-action="google action" data-ga-value="1337">CLICK ME</a>

function eventTracking() {
  if (window.LEXNOVA.tracking.do_not_track) return;
  else if (ahoy || window.mixpanel) {
    return true;
  } else {
    return;
  }
}

function eventTrackingProps(props) {
  try {
    JSON.parse(props);
  } catch (e) {
    return;
  }
  return JSON.parse(props);
}

function eventTrackingTrack(name, props) {
  if (eventTracking() && typeof name === "string") {
    if (ahoy) {
      ahoy.track(name, props);
    }

    if (window.mixpanel) {
      window.mixpanel.track(name, props);
    }
  }
}

if (eventTracking()) {
  // Add event listener to any element with the data attribute
  // [data-track-event='click']
  document.addEventListener(
    "DOMContentLoaded",
    function() {
      const event_analytics_clicks = document.querySelectorAll(
        "[data-track-event='click']"
      );
      event_analytics_clicks.forEach(function(item) {
        item.addEventListener("click", () => {
          eventTrackingTrack(
            item.dataset.eventName,
            eventTrackingProps(item.dataset.eventProperties)
          );
        });
      });
    },
    false
  );

  // Add event listener to any element with the data attribute
  // [data-external='true']
  document.addEventListener(
    "DOMContentLoaded",
    function() {
      const external_links = document.querySelectorAll(
        "[data-external='true']"
      );
      external_links.forEach(function(item) {
        item.addEventListener("click", () => {
          eventTrackingTrack("External Link", {
            url: item.getAttribute("href")
          });
        });
      });
    },
    false
  );

  // Track any events defined in AET
  if (window.AET) {
    window.AET.forEach(item => {
      eventTrackingTrack(item.name, item.props);
    });
  }

  window.addEventListener("AET", e => {
    eventTrackingTrack(e.detail.name, e.detail.props);
  });
}
