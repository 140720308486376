import { getElements } from 'js-utils/src/selector';

import init from '../../common/base/init';

// modal

init(() => {
  const modal = document.querySelector('#promo-video-modal');
  if(!modal) return;

  const playerContainer = modal.querySelector('.-player');

  modal.A11yDialogInstance.on('show', () => {
    playerContainer.innerHTML = modal.querySelector('.js--player-markup').innerHTML;
  });

  modal.A11yDialogInstance.on('hide', () => {
    playerContainer.innerHTML = '';
  });
});

// cta's

init(() => {
  const containers = getElements('.promo-video');
  if(!containers.length) return;

  containers.forEach((container) => {
    const triggerBtn = container.querySelector('.js--trigger-btn');
    const triggerImg = container.querySelector('.js--trigger-img');
    if(!triggerBtn || !triggerImg) return;

    const showTriggerBtn = () => {
      triggerBtn.classList.remove('is-hidden');
    };

    if(triggerImg.complete) {
      showTriggerBtn();
    }
    else {
      triggerImg.addEventListener('load', showTriggerBtn);
    }
  });
});
