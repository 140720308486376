// Grille: Masonry
// Inspired by: https://medium.com/@andybarefoot/a-masonry-style-layout-using-css-grid-8c663d355ebb

import { getElements } from 'js-utils/src/selector';
import imagesLoaded from 'imagesloaded';

import init from '../base/init';

init(() => {
  if(!('gridTemplateRows' in document.createElement('div').style)) return;

  const containers = getElements('.grille--do-masonry');
  if(!containers.length) return;

  containers.forEach((container) => {
    const grid = container.querySelector('.grille__inner');

    const resizeItem = (item) => {
      const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
      const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
      const rowSpan = Math.ceil((item.querySelector('.grille__item__inner').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
      item.style.gridRowEnd = `span ${rowSpan}`;
    };

    const resizeAllItems = () => {
      const allItems = grid.querySelectorAll('.grille__item');
      for(let x=0; x<allItems.length; x++){
        resizeItem(allItems[x]);
      }
    };

    const resizeInstance = (instance) => {
      const item = instance.elements[0];
      resizeItem(item);
    };

    container.classList.add('grille--masonry');
    window.addEventListener('resize', resizeAllItems);
    resizeAllItems();

    const allItems = grid.querySelectorAll('.grille__item');
    for(let x=0; x<allItems.length; x++) {
      imagesLoaded(allItems[x], resizeInstance);
    }
  });
});
