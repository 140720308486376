import init from "../base/init";
import { addEventListener } from "js-utils/src/event";
import { addClass, removeClass } from "js-utils/src/attribute";
import { getElements } from "js-utils/src/selector";

init(() => {
  const dateTypeInput = getElements(".js--date-filter-type");
  const dateFromInput = getElements(".js--date-filter-from")[0];
  const dateToInput = getElements(".js--date-filter-to")[0];

  if (dateTypeInput.length >= 1) {
    const ToggleHiddenInputs = value => {
      if (value === "" || value === undefined) {
        addClass(dateFromInput.parentElement, "is-hidden");
        addClass(dateToInput.parentElement, "is-hidden");
        return;
      }
      removeClass(dateFromInput.parentElement, "is-hidden");
      removeClass(dateToInput.parentElement, "is-hidden");
    };
    const ToggleDisableInputs = value => {
      if (value === "" || value === undefined) {
        dateFromInput.setAttribute("disabled", true);
        dateFromInput.value = "";
        dateToInput.setAttribute("disabled", true);
        dateToInput.value = "";
        return;
      }
      if (dateFromInput.getAttribute("disabled")) {
        dateFromInput.removeAttribute("disabled");
        dateToInput.removeAttribute("disabled");
        return;
      }
    };

    addEventListener(dateTypeInput, "change", e => {
      ToggleHiddenInputs(e.currentTarget.value);
      ToggleDisableInputs(e.currentTarget.value);
    });

    if (dateTypeInput.value !== "") {
      ToggleHiddenInputs(dateTypeInput[0].value);
      ToggleDisableInputs(dateTypeInput[0].value);
    }
  }
});
