const options = {
  removeItems: true,
  removeItemButton: true,
  shouldSort: false,
  resetScrollPosition: false,
  renderSelectedChoices: 'always',
  loadingText: 'Laddar…',
  noResultsText: 'Inga resultat',
  noChoicesText: 'Inga alternativ',
  itemSelectText: 'Klicka för att välja',
  addItemText: (value) => {
    return `Klicka för att välja <b>“${value}”</b>`;
  },
  classNames: {
    selectedState: 'is-selected',
  },
  callbackOnCreateTemplates: function (template) {
    return {
      choice: (classNames, data) => {
        return template(`
          <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable} ${data.selected ? classNames.selectedState : '' } " data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
            ${data.label}
          </div>
        `);
      },
    };
  },
};

export default options;
