import init from "../../common/base/init";

import { getElements } from "js-utils/src/selector";

// modal

init(() => {
  const modals = document.querySelectorAll(".wp-video-modal");
  if (!modals) return;

  modals.forEach((modal) => {
    modal = modal.closest(`.a11ydialog`);
    const playerContainer = modal.querySelector(".-player");

    modal.A11yDialogInstance.on("show", () => {
      playerContainer.innerHTML = modal.querySelector(
        ".js--player-markup"
      ).innerHTML;
    });

    modal.A11yDialogInstance.on("hide", () => {
      playerContainer.innerHTML = "";
    });
  });
});

// cta's

init(() => {
  const containers = getElements(".wp-video");
  if (!containers.length) return;

  containers.forEach((container) => {
    const triggerBtn = container.querySelector(".js--trigger-btn");
    const triggerImg = container.querySelector(".js--trigger-img");
    if (!triggerBtn || !triggerImg) return;

    const showTriggerBtn = () => {
      triggerBtn.classList.remove("is-hidden");
    };

    if (triggerImg.complete) {
      showTriggerBtn();
    } else {
      triggerImg.addEventListener("load", showTriggerBtn);
    }
  });
});

// Get Vimeo video thumbnails

export const initVimeoThumbnails = () => {
  const getVimeoThumbnail = async (videoId) => {
    const url = `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}&width=1920&height=1080`;
    const imageUrl = fetch(url)
      .then((response) => response.json())
      .then((json) => json.thumbnail_url);

    return await imageUrl;
  };

  const vimeoVideos = document.querySelectorAll(`.wp-video`);
  if (vimeoVideos.length <= 0) {
    return null;
  }

  Array.from(vimeoVideos).map(async (video) => {
    const videoId = video.dataset[`videoid`];
    const imageUrl = await getVimeoThumbnail(videoId);
    const imageTag = document.createElement(`img`);
    imageTag.src = imageUrl;

    video.prepend(imageTag);
  });
};

init(() => {
  initVimeoThumbnails();
});
