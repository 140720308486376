import Cookies from "js-cookie";

const step1 = () => {
  const rangeSlider = document.getElementById("range-slider");
  const rangeInput = document.getElementById("range-slider-number-input");
  const columnsWrapper = document.querySelector(".columns-wrapper");
  const packages = columnsWrapper.querySelectorAll(".column");
  const columnSections = columnsWrapper.querySelectorAll(".column__section");
  const discountTooltip = document.querySelector(
    ".range-slider-wrapper__discount-tooltip"
  );
  const discountPercentageEl = document.querySelector(".discount-percentage");
  let customPlanMessageOpen = false;
  let seatAmount = 1;

  let priceTimer;

  const ValidateBasePackage = lineItems => {
    return Object.keys(lineItems).length >= 1;
  };
  const ValidateFlexPackage = lineItems => {
    return Object.keys(lineItems).length >= 2;
  };
  const ValidatePremiumPackage = lineItems => {
    const playCategories = Object.values(lineItems).filter(item => {
      return item.orderable_attributes.product_id === "71" ? item : null;
    });
    const analysisCategories = Object.values(lineItems).filter(item => {
      return item.orderable_attributes.product_id === "70" ? item : null;
    });
    return (
      Object.keys(playCategories).length >= 1 &&
      Object.keys(analysisCategories).length >= 1
    );
  };
  const ValidateOrder = orderJSON => {
    const pkg = JSON.parse(orderJSON).order.pricing_package_id;
    const lineItems = JSON.parse(orderJSON).order.order_line_items_attributes;

    switch (pkg) {
      case "1":
        return ValidateBasePackage(lineItems);
      case "2":
        return ValidateFlexPackage(lineItems);
      case "3":
        return ValidatePremiumPackage(lineItems);
    }
  };

  // This function builds the JSON for saving an order
  // and grabbing the price from the API
  const buildOrder = pricePackage => {
    let orderJSON = new Object();
    let lineItems = new Object();

    const products = pricePackage.querySelectorAll(".column__section");
    const pricingPackageID = pricePackage.querySelector(".pricing_package_id");

    // Iterate through products
    products.forEach(product => {
      const checkbox = product.querySelector(".-select-product");
      const productId = product.querySelector(".-product-id");

      // If the product is selected
      if (checkbox && checkbox.checked) {
        const legalAreas = product.querySelector(".js--choices-purchaseflow");

        // If the product has legal areas
        if (legalAreas) {
          const selectedOptions = legalAreas.querySelectorAll("option");

          // Iterate through all selected legal areas
          selectedOptions.forEach(option => {
            const liHash = Math.round(Math.random() * 100);

            const newLineItem = {
              [liHash]: {
                cancelled: "0",
                orderable_type: "OrderedProduct",
                orderable_attributes: {
                  product_id: productId.value,
                  category_id: option.value,
                  archive: "1"
                },
                _destroy: "false"
              }
            };

            lineItems = Object.assign({}, lineItems, newLineItem);
          });

          // If the product doesn't have legal areas,
          // then add the entire product (category_id: "")
        } else {
          const liHash = Math.round(Math.random() * 100);

          const newLineItem = {
            [liHash]: {
              cancelled: "0",
              orderable_type: "OrderedProduct",
              orderable_attributes: {
                product_id: productId.value,
                category_id: "",
                archive: "1"
              },
              _destroy: "false"
            }
          };

          lineItems = Object.assign({}, lineItems, newLineItem);
        }
      }
    });

    orderJSON.pricing_package_id = pricingPackageID.value;
    orderJSON.users_amount = seatAmount;
    orderJSON.status = "created";
    orderJSON.order_line_items_attributes = lineItems;

    orderJSON = JSON.stringify({
      order: orderJSON
    });

    return orderJSON;
  };

  const getPrices = (pkg = 0) => {
    const getPackagePrice = pkg => {
      // Get the JSON needed to create order
      const orderJSON = buildOrder(pkg);
      const packagePriceContainer = pkg.querySelector(".price");
      const packagePrice = pkg.querySelectorAll(".-dynamic-price");

      packagePriceContainer.classList.add("--loading");

      // Create a new XMLHttpRequest
      let xmlhttp = new XMLHttpRequest();

      // Listen for state change
      xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
          // If successful, grab the price and update
          if (xmlhttp.status == 200) {
            const response = JSON.parse(xmlhttp.responseText);
            Array.from(packagePrice).map(
              item => (item.innerHTML = response["price"])
            );
          } else {
            // Otherwise, reset to base price
            Array.from(packagePrice).map(
              item => (item.innerHTML = item.dataset.basePrice)
            );
          }

          packagePriceContainer.classList.remove("--loading");
        }
      };

      // Call the API
      xmlhttp.open("POST", "/api/v1/orders/price");
      xmlhttp.setRequestHeader("Content-Type", "application/json");

      // Send the parsed JSON
      xmlhttp.send(orderJSON);
    };

    // If a package was specified in the getPrices() variable,
    // only query and update that package
    if (pkg) {
      getPackagePrice(pkg);

      // Otherwise, iterate through all packages
    } else {
      packages.forEach(pkg => getPackagePrice(pkg));
    }
  };

  const createOrder = pkg => {
    const loadingSpinner = document.querySelector(".columns-wrapper");
    loadingSpinner.classList.add("--loading");

    document.querySelectorAll(".-place-order").forEach(el => {
      el.disabled = true;
    });

    // Get the JSON needed to create order
    const orderJSON = buildOrder(pkg);

    // Create a new XMLHttpRequest
    let xmlhttp = new XMLHttpRequest();

    // Listen for state change
    xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState == XMLHttpRequest.DONE) {
        // If successful, grab the price and update
        if (xmlhttp.status == 200) {
          const response = JSON.parse(xmlhttp.responseText);

          if (response["token"]) {
            // Store a cookie with the order token
            Cookies.set("order_token", response["token"], { expires: 15 });

            // Redirect user to order page
            // TODO: Improve this redirect
            window.location.href = "/bestall/" + response["token"];
          }
        } else {
          document.querySelectorAll(".-place-order").forEach(el => {
            el.disabled = false;
          });
          showValidationNotice(pkg);
        }

        loadingSpinner.classList.remove("--loading");
      }
    };

    // Call the API
    xmlhttp.open("POST", "/api/v1/orders/");
    xmlhttp.setRequestHeader("Content-Type", "application/json");

    if (ValidateOrder(orderJSON)) {
      xmlhttp.send(orderJSON);
    } else {
      loadingSpinner.classList.remove("--loading");
      document.querySelectorAll(".-place-order").forEach(el => {
        el.disabled = false;
      });
      showValidationNotice(pkg);
    }
  };

  // Add listener events to the package purchase buttons
  document.querySelectorAll(".-place-order").forEach(el => {
    const pkg = el.closest(".column");

    el.addEventListener("click", function(event) {
      if (!pkg) return;

      createOrder(pkg);

      event.preventDefault();
    });
  });

  // Check which products are active and enable
  // the legal area dropdowns
  const enableLegalAreas = () => {
    columnSections.forEach(el => {
      const checkbox = el.querySelector(".-select-product");
      const legalAreaDropdown = el.querySelector(".js--choices-purchaseflow");

      if (legalAreaDropdown) {
        if (checkbox && checkbox.checked) {
          legalAreaDropdown.CHOICES_INSTANCE.enable();
        } else if (checkbox) {
          legalAreaDropdown.CHOICES_INSTANCE.disable();
        }
      }
    });
  };

  document.querySelectorAll(".js--choices-purchaseflow").forEach(el => {
    let instance = el.parentElement.querySelector("select").CHOICES_INSTANCE;
    const parentPackage = el.closest(".column");
    const addonPrice = parentPackage.querySelector(".addon_price").value;

    el.addEventListener("removeItem", function() {
      if (instance.getValue().length == 0) {
        instance.input.element.classList.remove("-additional-legal-area");
        instance.input.element.setAttribute("placeholder", "Välj rättsområde");
      }

      clearTimeout(priceTimer);
      priceTimer = setTimeout(function() {
        getPrices(parentPackage);
      }, 500);
    });

    el.addEventListener("addItem", function() {
      if (instance.getValue().length > 0) {
        instance.input.element.classList.add("-additional-legal-area");
        instance.input.element.setAttribute(
          "placeholder",
          "Lägg till extra rättsområde (+" + addonPrice + " kr)"
        );
      }

      clearTimeout(priceTimer);
      priceTimer = setTimeout(function() {
        getPrices(parentPackage);
      }, 500);
    });
  });

  // Add listener events to the product checkboxes
  // and radio inputs
  const addProductListeners = () => {
    columnSections.forEach(el => {
      const checkbox = el.querySelector(".-select-product");
      const parentPackage = el.closest(".column");

      if (checkbox) {
        checkbox.addEventListener("change", () => {
          enableLegalAreas();
          getPrices(parentPackage);
        });
      }
    });
  };

  let updateTooltip = () => {
    discountTooltip.style.left = (seatAmount - 1) * 7.14285714 + "%";

    // this offsets discount tooltip as range slider progresses
    // because it's thumb doesn't go 100% from the left but more like (100% - it's width)
    discountTooltip.style.marginLeft =
      -34 - (38 / 14) * (seatAmount - 1) + "px";

    if (seatAmount >= 5 && seatAmount != 15) {
      discountTooltip.classList.add("visible");
    } else {
      discountTooltip.classList.remove("visible");
    }

    if (seatAmount >= 5 && seatAmount < 10) {
      discountPercentageEl.innerHTML = "10";
    } else if (seatAmount >= 10) {
      discountPercentageEl.innerHTML = "15";
    }
  };

  let updateSeatAmount = el => {
    seatAmount = el.value;
    rangeInput.value = seatAmount;
    rangeSlider.value = seatAmount;
    rangeInput.setAttribute("value", seatAmount);

    //tooltip
    updateTooltip();

    if (seatAmount == 15) {
      customPlanMessageOpen = true;
      columnsWrapper.classList.add("-columns-hidden");
    } else if (seatAmount < 15 && customPlanMessageOpen) {
      customPlanMessageOpen = false;
      columnsWrapper.classList.remove("-columns-hidden");
    }
  };

  rangeSlider.addEventListener("input", function() {
    updateSeatAmount(this);
  });

  rangeSlider.addEventListener("change", function() {
    updateSeatAmount(this);

    getPrices();
  });

  rangeInput.addEventListener("input", function() {
    //if entered number is larger than max attribute we force value to be the last valid number entered
    if (parseInt(this.value) > parseInt(this.getAttribute("max"))) {
      this.value = this.getAttribute("value");
    } else if (this.value == "") {
      this.value = 1;
    }
    updateSeatAmount(this);
  });

  rangeInput.addEventListener("change", function() {
    updateSeatAmount(this);

    getPrices();
  });

  // Enable/disable legal areas
  enableLegalAreas();

  // Attach event listeners to product radios/checkboxes
  addProductListeners();

  // Load real prices the first time
  getPrices();

  const showValidationNotice = pkg => {
    pkg.querySelector(".validation-notice").classList.add("-visible");

    setTimeout(function() {
      pkg.querySelector(".validation-notice").classList.remove("-visible");
    }, 5000);
  };

  const hideValidationNotice = pkg => {
    pkg.querySelector(".validation-notice").classList.remove("-visible");
  };

  //hide validation notice if some inputs changed
  document.querySelectorAll("form.column input").forEach(function(el) {
    const pkg = el.closest(".column");

    el.addEventListener("change", function() {
      hideValidationNotice(pkg);
    });
  });

  //tab navigation on mobile
  document.querySelectorAll(".column__heading").forEach(function(el) {
    el.addEventListener("click", function() {
      let targetColumn = this.getAttribute("data-columnname");

      document
        .querySelectorAll(".columns-wrapper__column-wrapper")
        .forEach(function(el) {
          el.classList.remove("-active");
        });

      document
        .querySelector(
          ".columns-wrapper__column-wrapper[data-column=" + targetColumn + "]"
        )
        .classList.add("-active");
    });
  });

  const tooltips = document.querySelectorAll(".tooltip__trigger");

  const hideAllTooltips = () => {
    const visibleTooltips = document.querySelectorAll(".tooltip--visible");
    visibleTooltips.forEach(item => item.classList.remove("tooltip--visible"));
  };

  document.addEventListener("click", function(event) {
    hideAllTooltips();

    tooltips.forEach(tooltip => {
      tooltip.addEventListener("click", function(e) {
        e.preventDefault();
      });

      if (tooltip.contains(event.target)) {
        const tooltipWrapper = tooltip.closest(".tooltip");
        tooltipWrapper.classList.add("tooltip--visible");
      }
    });
  });
};

export default step1;
