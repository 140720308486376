import init from '../../../common/base/init';
import initChoices from '../../../common/components/choices';

init(() => {
  const container = document.querySelector('.purchase-company-create');
  if(!container) return;

  const modal = container.closest('.a11ydialog');

  const trackAjax = () => {
    const form = container.querySelector('form');
    if(!form) return;

    form.addEventListener('ajax:success', (e) => {
      const [data] = e.detail;

      if(data.status === 'error') {
        form.outerHTML = data.form;
        modal.dispatchEvent(new CustomEvent('scroll-to-top'));
        initChoices(form);
        trackAjax();
      }
      else { // success
        window.location.reload();
      }
    });
  };

  trackAjax();
});
