import { getElements } from 'js-utils/src/selector';
import { debounce } from 'js-utils/src/function';

import init from './init';

// sets [data-omasks] on an element

init(() => {
  var elems = getElements('.js--omasks');
  if(!elems.length) return;

  const setAttrs = (elem) => {
    let edges = [];

    if(elem.scrollWidth > elem.offsetWidth) {
      if(elem.scrollLeft > 5) edges.push('left');
      if(elem.scrollLeft + elem.offsetWidth <= elem.scrollWidth - 5) edges.push('right');
    }

    // if(elem.scrollHeight > elem.offsetHeight) {
    //   if(elem.scrollTop > 5) edges.push('top');
    //   if(elem.scrollTop + elem.offsetHeight <= elem.scrollHeight - 5) edges.push('bottom');
    // }

    if(edges.length) {
      elem.setAttribute('data-omasks', edges.join(' '));
    }
    else {
      elem.removeAttribute('data-omasks');
    }
  };

  elems.forEach((elem) => {
    elem.addEventListener('scroll', () => {
      setAttrs(elem);
    });

    window.addEventListener('resize', debounce(300, () => {
      setAttrs(elem);
    }));

    setAttrs(elem);
  });
});
